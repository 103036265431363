<template>
  <div 
    class="main-pop"
     :class="{'show':showPopups}">
      <div
      :id="ids"
      class="box-modal"
      @click="function(){return false}">
        <div class="d-block text-center">
          <div 
            class="close-pop"
            @click="hidePopup()"
          >
            <svg width="24px" height="24px" viewBox="0 0 24 24">
              <g data-name="close">
                <rect width="24" height="24" transform="rotate(180 12 12)" opacity="0"/>
                <path d="M13.41 12l4.3-4.29a1 1 0 1 0-1.42-1.42L12 10.59l-4.29-4.3a1 1 0 0 0-1.42 1.42l4.3 4.29-4.3 4.29a1 1 0 0 0 0 1.42 1 1 0 0 0 1.42 0l4.29-4.3 4.29 4.3a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42z"/>
              </g>
            </svg>
          </div>
          <div v-if="ids==='popupToken' || ids==='popupReward'">
            <b-img :src="dataPopup.image" center rounded class="img-box"></b-img>
            <h3 class="title-popup">{{ dataPopup.title }}</h3>
            <p class="txt-desc">{{ dataPopup.description }}</p>
            <b-button
              class="mt-3 btn-defaultx"
              block
              @click="actionButton()"
              >{{ dataPopup.txtButton }}</b-button
            >
           </div>
           <div v-if="ids==='popupInfo'" style="position: relative;">
              <div class="inform">
                <SyaratAda/>
                <b-button
                  class="mt-3 btn-oke"
                  block
                  @click="hidePopup()"
                  ></b-button
                >
              </div>
           </div>
           <div v-if="ids==='popupService'" style="position: relative;">
            <div class="head-service"></div>
              <div class="service">
                <div class="list-store">
                  <div v-for="lists in storeList" :key="lists.id" class="box-list">
                    <div class="head-list">
                      <div class="head-list-desc">
                        <h3>Rp.{{lists.service_charge}}</h3>
                        Untuk +{{lists.token}} token 
                        </div>
                    </div>
                    <div>{{lists.description}}</div>
                    <b-button
                      class="mt-1 btn-defaultx-list"
                      block
                      @click="actionPull(lists.channel,lists.keyword,lists.adn)"
                      ></b-button
                    >
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div>
      <div class="backdrop-pop" @click="hidePopup()"/>
  </div>
</template>
<script>
import { mapGetters, mapMutations, mapState } from 'vuex'
import SyaratAda from '@/components/Syarat.vue'
export default {
  components: {
    SyaratAda,
  },
  data: function() {
  return {};
},
  props: {
      dataPopup: Object,
      ids: String,
    },
  computed: {
    ...mapGetters({
      showPopups: 'getPopupShow',
      storeList: 'getStore',
    }),
    ...mapState([
      'telco',
      'android'
    ]),
  },
  methods: {
    ...mapMutations({
      setPopups: 'SET_GAME_POPUP_SHOW',
    }),
      hidePopup() {
        this.setPopups(false)
      },
      actionButton(id) {
        console.log('panggil ini',id)
        //   this.$store.state.coin = 1
          // this.hidePopup(this.ids)
          this.setPopups(false)
          this.$emit('addCoin')
          console.log('clicked', this.$store.state.coin);
      },
      actionPull(channel,keyword,adn){
        this.setPopups(false)
        if(this.android && window.androidExt){
          
          if(channel==='SMS'){
            window.androidExt.postMessage("{'methode':'sms','var_a':'"+adn+"','var_b':'"+keyword+"'}")
          }else if(channel==='UMB'){
            // keyword = keyword.slice(0, keyword.length - 1), keyword += "%23";
            window.androidExt.postMessage("{'methode':'umb','var_a':'"+keyword+"','var_b':'null'}")
          }else if(channel==='WAP'){
            window.androidExt.postMessage("{'methode':'wap','var_a':'http://117.54.3.23:1481/"+this.telco+"?aid=1&adn="+adn+"&keyword="+keyword+"','var_b':'null'}")
          }else if(channel==='GPLAY'){
            keyword= keyword.slice(0, -2)
            window.androidExt.postMessage("{'methode':'gplay','var_a':'"+keyword+"','var_b':'null'}")
          }else{
            window.androidExt.postMessage("{'methode':'msg','var_a':'SERVICE BELUM TERSEDIA','var_b':'null'}")
          }
        }else{
          if(channel==='SMS'){
            location.href = 'sms:' + adn + '?body=' + keyword;
          }else if(channel==='UMB'){
            keyword = keyword.slice(0, keyword.length - 1);
            keyword += "%23";
            location.href = 'tel:' + keyword;
          }else if(channel==='WAP'){
            window.open("http://117.54.3.23:1481/"+this.telco+"?aid=1&adn="+adn+"&keyword="+keyword+"","_blank");
          }
        }
      },
      callDataService(){
        console.log(this.storeList)
      },
  }
};
</script>

<style scoped>
/* /deep/ .wd-custom {
    width: 230px !important;
}
/deep/ .title-popup {
    font-size: 1.1rem;
}
/deep/ .txt-desc {
    font-size: .8rem;
} */
.backdrop-pop{
  width:100vw;
  height:100vh;
  background:#0000009e;
  position: relative;
  z-index:1001;
}
.main-pop{
  display:none;
}
.main-pop.show{
  display: block;
}
.box-modal{
    color: #fff !important;
    background-image: url("../assets/bg-p.png");
    box-shadow: inset #d100b6 0px 0px 6px 4px;
    background-repeat: repeat;
    width: 80vw;
    height: calc(93vh - 3rem);
    min-width: 15rem !important;
     max-width: 30rem;
    margin: auto;
    position: absolute;
    z-index: 1002;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    padding: 1rem;
    border-radius: 25px;
    
    text-transform: uppercase;
}

.box-modal h3.title-popup{
    font-size: 5.5vh;
    margin-top: 0.5rem;
    font-weight: 600;
    color: #742203;
}
.img-box{
  height:32vh;
}
.txt-desc{
  font-size:4vh;
}
.close-pop{
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
    width: 28px;
    height: 27px;
    fill: #f0d769;
    background: #f28902;
    border-radius: 13px;
    z-index: 25;
}
.btn-defaultx, .btn-defaultx-list{
    position: relative;
    background-image: url("../assets/btn-buy.png");
    width: 59vw;
    max-width: 264px;
    height: 13vw;
    border: none;
    background-position: center;
    background-color: transparent !important;
    background-size: contain;
    background-repeat: no-repeat;
}
.btn-oke{
  background-image: url("../assets/oke-btn.png");
  width: 59vw;
  max-width: 264px;
  height: 13vw;
  border: none;
  background-position: center;
  background-color: transparent !important;
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  bottom: -4.2rem;
  left: 16%;
  right: 0;
}
.title-box{
  font-size: 21px;
    font-weight: 700;
    background: #96ecff;
    border: 5px solid #fff;
    padding: 15px 0px;
    border-radius: 20px;
    color: #95b3db;
    box-shadow: inset 0 0 10px #529cff;
}
.btn-defaultx .light, .btn-defaultx-list .light{
    display: block;
    background-color: #d0f62159;
    position: absolute;
    height: 14px;
    border-radius: 5px;
    left: 0px;
    width: calc(100% - 10px);
    top: 4px;
    margin: 0 5px;
}

.btn-defaultx{
  width: 68% !important;
  position: absolute;
  bottom: -3.2rem;
  left: 0;
  right: 0;
}
.service,.inform{
    margin-top: 1.5rem;
    background: #00000060;
    border-radius: 25px;
    overflow: scroll;
}
.service{
  margin-top: 7.5rem;
  height: calc( 100vh - 18.3rem);
}
.inform{
  color: #1b7bfc;
  height: calc( 90vh - 9.5rem);
}
.head-service{
  background-image: url("../assets/knopi.png");
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  width: 100%;
  min-width: 11rem !important;
  max-width: 30rem;
  height: 12rem;
  top: -146px;
  z-index: 20;
}

.box-list{
    padding: 0.5rem;
    color: #6d6d6d;
    font-weight: 700;
    border-radius: 20px;
    margin: 0.5rem;
    background-color: #715ec0;
    position: relative;
    padding-bottom: 1rem;
    text-transform: none;
    border-bottom: 5px solid #433a8c;
}
.head-list{
   background: #1b7bfc;
   background-image: url("../assets/coins.png");
   background-size: 7rem;
   background-size: cover;
   background-repeat: no-repeat;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    padding-bottom: 1rem;
    color: #ffffd7;
    position: relative;
    padding-top: 1rem;
    margin: -0.5rem;
    margin-bottom: 1rem;
}
.head-list-desc{
    display: inline-block;
    background: #00000087;
    padding: 0.5rem 1rem;
    font-weight: 100;
    font-size: 17px;
    border-radius: 9px;
}
.head-list-desc span{
  font-weight: 700;
  color: #ffffff;
  display: block;
  font-size: 25px;
}
#popupInfo{
  width: 90vw;
  max-width: 41rem;
}
@keyframes modalshow {
  0%   { top:400px;}
  100% {top:0px;}
}
.main-pop.show .box-modal{
  animation-name: modalshow;
  animation-duration: 1s;
}
</style>
